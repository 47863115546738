'use client';

import { useResponsiveQuery } from 'atomic-layout';
import React from 'react';

import Icon from '@/components/v3/Icon';
import styles from './InboundV3MobileInfo.module.css';
import 'keen-slider/keen-slider.min.css';
import { useKeenSlider } from 'keen-slider/react';

import { benefits } from '../InboundV3Info/acquire-benefits';
import ServiceAvailabilityBadge from './ServiceAvailabilityBadge';

type InboundV3MobileInfoProps = {
  children: React.ReactNode;
};

const InboundV3MobileInfo: React.FC<InboundV3MobileInfoProps> = ({
  children,
}) => {
  const isMobile = useResponsiveQuery({ to: 'md' });
  const [sliderRef] = useKeenSlider({
    slides: { perView: 'auto', spacing: 16 },
  });

  return (
    <>
      <div className="flex relative flex-col md:justify-center md:pt-0 w-full">
        {isMobile && (
          <div className="md:hidden">
            <div className="flex relative flex-col justify-center items-center md:p-0 md:mt-0 w-full bg-display-50">
              <div className="px-24 pt-24 pb-16">
                <ServiceAvailabilityBadge />
                <h4 className="mt-16 font-display text-2xl font-bold">
                  Taxas personalizadas de acordo com o seu negócio?
                </h4>
                <p>
                  Fale com a gente e conheça as melhores ofertas que se encaixam
                  no seu negócio
                </p>
              </div>
              <div className="pl-24 w-full">
                <div
                  ref={sliderRef}
                  className="overflow-visible mb-8 keen-slider"
                >
                  {benefits.map(({ phrase, icon }, index) => (
                    <div
                      key={index}
                      className="flex-col p-16 min-w-[133px] max-w-[133px] bg-display-100 rounded-md border-[1px] border-display-300 keen-slider__slide"
                    >
                      <Icon
                        name={icon}
                        className="text-stone-500 fill-current"
                      />
                      <p className="mt-4 font-sharon paragraph-14">{phrase}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="overflow-hidden pt-16 bg-display-50">
              <div className={styles.formBox}></div>
            </div>
            <div className="flex flex-col gap-16 justify-center md:p-0 px-24 pb-[24px] md:mt-0 w-full h-full">
              <h6 className="font-display font-bold">
                Informe abaixo os seus dados
              </h6>
              {children}
            </div>
          </div>
        )}
        <div className="hidden md:flex flex-col gap-16 justify-center md:p-0 md:mt-0 w-full">
          {children}
        </div>
      </div>
    </>
  );
};
export default InboundV3MobileInfo;
