import React from 'react';
import Link from 'next/link';
import Icon, { IconsNames } from '@/components/v3/Icon';

export const benefits: { phrase: string; icon: IconsNames }[] = [
  {
    phrase: 'Frete grátis e entrega em 1 dia útil',
    icon: 'auto-truck',
  },
  {
    phrase: 'Aceite + de 50 bandeiras e vouchers',
    icon: 'card-credit',
  },
  {
    phrase: 'Atendimento humano em 5 segundos',
    icon: 'customer-support',
  },
  {
    phrase: 'Receba suas vendas no mesmo dia',
    icon: 'money-time',
  },
  {
    phrase: 'Conta PJ grátis com cartão de crédito',
    icon: 'cash-phone',
  },
];

const AcquireBenefits: React.FC = () => {
  return (
    <div className="pb-16">
      <h4 className="mt-16 font-display text-2xl xl:text-3xl text-display-900 heading-4">
        Taxas personalizadas de acordo com o seu negócio?
      </h4>
      <p className="text-current paragraph-18">
        Fale com a gente e conheça as melhores ofertas que se encaixam no seu
        negócio
      </p>
      <ul className="flex flex-col gap-12 mt-16 mb-16">
        {benefits.map(({ phrase, icon }, index) => (
          <li
            key={index}
            className="flex p-16 bg-display-100 rounded border-[1px] border-display-300"
          >
            <Icon name={icon} className="text-stone-500 fill-current" />
            <p className="ml-16 font-sharon paragraph-16">{phrase}</p>
          </li>
        ))}
      </ul>

      <Link href="tel:3004 9681" legacyBehavior>
        <a className="flex flex-col cursor-pointer">
          <p className="text-current paragraph-16">
            Adquira também pelo telefone{' '}
            <span className="font-medium text-stone-500">3004 9681</span>
          </p>
        </a>
      </Link>
    </div>
  );
};

export default AcquireBenefits;
