import classNames from 'classnames';
import { isOutOfAttendanceTime } from '@/containers/InboundV2/helpers/isOutOfAttendanceTime';

const ServiceAvailabilityBadge: React.FC = () => {
  return (
    <div
      className={classNames(
        'flex w-full justify-between gap-16 py-4 px-16 bg-stone-100 rounded-base',
        {
          'bg-stone-100': !isOutOfAttendanceTime(),
          'bg-blue-100': isOutOfAttendanceTime(),
        },
      )}
    >
      <p className="text-display-800 paragraph-16">Atendimento</p>
      <div className="flex items-center font-medium">
        <span
          className={classNames('m-4 w-[14px] h-[14px] rounded-full', {
            'bg-green-400': !isOutOfAttendanceTime(),
            'bg-blue-400': isOutOfAttendanceTime(),
          })}
        />
        {isOutOfAttendanceTime() ? 'Agendado' : 'Disponível'}
      </div>
    </div>
  );
};
export default ServiceAvailabilityBadge;
