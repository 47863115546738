'use client';

import Image from 'next/image';
import styles from './InboundInfo.module.css';
import { useResponsiveQuery } from 'atomic-layout';

import AcquireBenefits from './acquire-benefits';
import AcquireHeader from './acquire-header';
import ContentFooterWithoutRoutes from '@/layouts/shared/ContentFooterWithoutRoutes';

type InboundV3InfoProps = {
  children: React.ReactNode;
  onPhoneClick: () => void;
};

const InboundV3Info: React.FC<InboundV3InfoProps> = ({
  children,
  onPhoneClick,
}) => {
  const isDesktop = useResponsiveQuery({ from: 'lg' });
  const isMobile = useResponsiveQuery({ to: 'md' });

  if (isMobile)
    return (
      <div className="w-full h-full">
        <AcquireHeader onPhoneClick={onPhoneClick} />
        <div>{children}</div>
        <ContentFooterWithoutRoutes />
      </div>
    );
  return (
    <div className="flex flex-col h-screen">
      <AcquireHeader onPhoneClick={onPhoneClick} />
      <div className="flex overflow-hidden justify-center min-h-[700px]">
        <div className="flex-1 bg-display-50"></div>
        <div className="overflow-hidden w-full max-w-[1324px]">
          <div className="flex h-full">
            <div className="hidden md:flex overflow-auto w-2/5 min-w-[420px] bg-display-50">
              <div className="hidden md:flex flex-row-reverse justify-items-end py-40 pr-24 xl:pr-80 pl-24 w-full">
                <div className="flex">
                  <div className="max-w-[520px]">
                    <Image
                      width={isDesktop ? 216 : 144}
                      height={isDesktop ? 202 : 135}
                      src="stone-cms/prod/maquininha_cartao_stone_4203d432d5.png"
                      alt="Produtos Stone - maquininha, cartão de crédito para empresas e pessoal e aplicativo com controle de vendas"
                    />
                    <AcquireBenefits />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-center items-center md:w-3/5 bg-display-50">
              <div className={styles.formContainer}>
                <div className="flex flex-col justify-center items-center max-w-[520px] h-screen">
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 bg-display-0"></div>
      </div>
      <ContentFooterWithoutRoutes />
    </div>
  );
};

export default InboundV3Info;
