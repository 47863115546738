'use client';

import InboundV2Info from './components/InboundV2InfoShell/InboundV2Info';
import InboundV2MobileInfo from './components/InboundV2InfoShell/InboundV2MobileInfo';
import InboundV3Info from './components/InboundV2InfoShell/InboundV3Info';
import InboundV3MobileInfo from './components/InboundV2InfoShell/InboundV3MobileInfo';
import { useCallback } from 'react';
import { handlePhoneOnInboundSiteClick } from '@/utils/Analytics';
import { useAdquiraExperiment } from '@/hooks/useAdquiraExperiment';

const InboundV2Container: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const onPhoneClick = useCallback(() => {
    handlePhoneOnInboundSiteClick('/adquira/');
  }, []);

  const { shouldEnterAdquiraExperiment } = useAdquiraExperiment();

  if (shouldEnterAdquiraExperiment)
    return (
      <>
        <InboundV3Info onPhoneClick={onPhoneClick}>
          <InboundV3MobileInfo>{children}</InboundV3MobileInfo>
        </InboundV3Info>
      </>
    );

  return (
    <>
      <InboundV2Info onPhoneClick={onPhoneClick}>
        <InboundV2MobileInfo onPhoneClick={onPhoneClick}>
          {children}
        </InboundV2MobileInfo>
      </InboundV2Info>
    </>
  );
};

export default InboundV2Container;
