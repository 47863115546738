'use client';

import Link from 'next/link';
import Icon from '@/components/v3/Icon';
import ServiceAvailabilityBadge from '../InboundV3MobileInfo/ServiceAvailabilityBadge';

type AcquireHeaderProps = {
  onPhoneClick: () => void;
};

const AcquireHeader: React.FC<AcquireHeaderProps> = ({ onPhoneClick }) => {
  return (
    <>
      <div className="flex justify-center py-10 px-24 w-full bg-display-0 border-b border-display-200 border-solid">
        <div className="flex justify-between items-center w-full max-w-[1324px]">
          <Link href="/" aria-label="Link para página inicial">
            <Icon
              name="logo/stone"
              className="w-[92px] h-[24px] fill-current"
            />
          </Link>
          <div className="flex">
            <div>
              <div className="hidden md:flex">
                <ServiceAvailabilityBadge />
              </div>
              <Link
                href="tel:30049681"
                className="md:hidden py-12 px-16 btn btn-primary btn-regular"
                onClick={onPhoneClick}
              >
                <p className="flex text-white paragraph-16">
                  <Icon name="phone" className="mr-8 text-white fill-current" />
                  Ligue
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AcquireHeader;
